// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";
import "../stylesheets/application.scss"

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all";
import "@activeadmin/activeadmin";

import "./ckeditor_base_path.js"
import "ckeditor4";

import "./offer_categories.js"
import "./sidebar.js"
import "./helper"
import "./drag_drop_upload"
import "./gob_rule.js"
import "./smart_offer"
import "./dynamically_setting_selects.js"
import "./staff_image_upload_crop"
import "./edit_image_media"
import "./gob_gallery"
import "./whatsapp"
import "./gms_chat_client"
require('bootstrap');
require("../images/favicon.ico");

import {ckeditor_plugins, toolbar_groups} from "./ckeditor_configs";

window.jQuery = $;
window.$ = $;

import "./ace/main"

window.setTimeout(() => {


    var cssPath = '/packs/js/ckeditor/ckeditor.css';
    var editors = document.querySelectorAll("textarea:not(.no-editor)");
    for (const editor of editors) {
        CKEDITOR.replace(editor, {
            language: 'en',
            uiColor: '#9AB8F3',
            contentsCss: [cssPath],
            stylesSet: 'default',
            extraPlugins: ckeditor_plugins(),
            filebrowserUploadUrl: '/admin/hotel_offer_data/upload_image',
            width: 1000,
            height:300,
            allowedContent: true,
            fullPage: $(this).hasClass('ckeditors-fullpage'),
            format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;address;div',
            toolbarGroups: toolbar_groups()
        });
    }

    CKEDITOR.stylesSet.add( 'default', [
        // Block Styles
        { name: 'Red h2',       element: 'h2',      attributes: { class: 'begruessung' } },
        { name: 'Center h2',       element: 'h2',      attributes: { class: 'center-text' } },
        { name: 'Pink h3',        element: 'h3',      attributes: { class: 'pinkh3' } },
        { name: 'Small title',       element: 'p',      attributes: { class: 'small-title' } },
        { name: 'Smaller text',       element: 'p',      attributes: { class: 'smaller-text ' } },
        { name: 'Center text',       element: 'h1,h2,h3,h4,h5,p',      attributes: { class: 'center-text' } },
        { name: 'Justify text',       element: 'h1,h2,h3,h4,h5,p',      attributes: { class: 'justify-text' } },

        // Object Styles
        {
            name: 'Standart list',
            element: 'ul',
            attributes: {
                class: 'standard-ul'
            }
        },
        {
            name: 'Brown list',
            element: 'ul',
            attributes: {
                class: 'inklusivleistungen brown-bullets'
            }
        },
        {
            name: 'Turquoise list',
            element: 'ul',
            attributes: {
                class: 'inklusivleistungen turquoise-bullets'
            }
        },
        {
            name: 'Blue list',
            element: 'ul',
            attributes: {
                class: 'inklusivleistungen blue-bullets'
            }
        },
        {
            name: 'Green list',
            element: 'ul',
            attributes: {
                class: 'inklusivleistungen green-bullets'
            }
        },
        {
            name: 'Default table',
            element: 'table',
            attributes: {
                class: 'leistungs-tabelle-neu leistungstabelle'
            }
        }
    ]);
}, 250);
