$(document).ready(function () {
    const $chatButtons = $(".view-chat-btn");
    const $chatPopup = $("#gms-chat-client-popup");
    const $chatIframe = $("#gms-chat-client-iframe");

    // Function to get query parameters
    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    // Open chat popup
    function openChatPopup(chatUrl) {
        $chatIframe.attr("src", chatUrl);
        $chatPopup.css("display", "block");
    }

    // Handle chat button clicks
    $chatButtons.on("click", function (e) {
        e.preventDefault();

        // Get the chat URL from the data attribute
        const chatUrl = $(this).data("chat-url");

        // Open the chat popup
        openChatPopup(chatUrl);
    });

    // Handle messages from iframe
    window.addEventListener("message", function (event) {
        if (event.data.action === "closeGMSChatClientIframe") {
            $chatPopup.css("display", "none");
        }
    });

    // Check for chat_room_id in query parameters
    const chatRoomId = getQueryParam("chat_room_id");
    if (chatRoomId) {
        const chatButton = $chatButtons.filter((index, button) => {
            return $(button).data("chat-room-id") == chatRoomId;
        }).first();

        if (chatButton.length) {
            const chatUrl = chatButton.data("chat-url");
            openChatPopup(chatUrl);
        }
    }
});
